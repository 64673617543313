
@import "mixins.scss";


@media (min-width: 1921px) {

    .productos {
        .cont-productos {
            .producto-min{
                .texts{
                    h3{
                        min-height: 3.5rem;
                    }
                }
            }
        }
    }

    .cont-dest {
        .dest {
            .imagen{
                height: 27rem;
            }
        }
    }
    
}

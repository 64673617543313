
@import "mixins.scss";

/* @include breakpoint(desktop){

}
@include breakpoint(biglaptop) {

}
@include breakpoint(laptop){

}
@include breakpoint(tablet) {

}
@include breakpoint(phablet) {

} */


@include breakpoint(mobileonly) {
  body {
    width: 100%;
    height: 100%;
  }

  html, body{
    font-size: 1rem;
  }

  .slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 256px;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
  }

  .slideout-menu-left {
    left: 0;
  }

  .slideout-menu-right {
    right: 0;
  }

  .slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    background-color: #FFF; /* A background-color is required */
    min-height: 100vh;
  }

  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }

  .slideout-open .slideout-menu {
    display: block;
  }
  .mob-only{
    display: block;
  }
  .top{
    flex-wrap: wrap;
    .redes{
      width: 100%;
    }
    .side-nav{
      flex-wrap: wrap;
      align-items: flex-start;
      a{
        width: 40%;
        &:first-of-type{
          width: 100%;
        }
      }
      .idiomas{
        margin-left: 15px;
      }
    }
  }
  .producto{
    flex-wrap: wrap;
    .note{
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      div{
        width: 40%;
        margin: 20px 10px;
      }
    }
    .imagenes{
      width: 100%;
      .carousel{
        .btn3d, .btnvideo{
          font-size: 1rem;
        }
      }
    }
    .contact{
      width: 100%;
    }
  }
  header{
    padding: 0 5vw;
    justify-content: flex-start;
  .toggle-button{
    background-color: transparent;
    color: white;
    font-size: 1.2rem;
    border: 0;
    margin-right: 20px;
    }
  }
  /* html, body{
    font-size: 4vw;
  } */
  .detalles{
    flex-wrap: wrap;
    .tengo, .cerca{
      width: 100%;
    }
  }
  #menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $beige;
    .logo{
      height: 3rem;
    }
    ul{
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        ul{
          padding-left: 1.5rem;
        }
      }
    }
    a{
      color: $brown1;
      padding: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.preg{
        background-color: $brown2;
        color: white;
        justify-content: center;
        img{
          margin-right: 1rem;
        }
      }
      &:hover{
        color: white;
        background-color: $brown1;
      }
    }
  }
  .faqs{
    .tabs{
      flex-wrap: wrap;
      button{
        width: 100%;
        font-size: 1rem;
        margin: 10px 0;
      }
    }
    .pregunta{
      h2{
        flex-wrap: wrap;
        strong{
          width: 100%;
          text-align: left;
        }
        span{
          margin: 10px 0 15px;
        }
      }
    }
  }
  .img-changer{
    flex-direction: column;
    margin: 55px auto 0;
    .img{
      width: 100%;
    }
    .textos-img{
      width: 100%;
    }
  }
  .empleados{
    .tabs{
      flex-wrap: wrap;
      button{
        width: 100%;
        font-size: 1rem;
        margin: 10px 0;
      }
    }
  }

  .secciontab{
    .swiper-slide{
      padding: 20px;
    }
  }

  .cont-servicios .serv{
    width: 100%;
  }

  .cont-dest{
    width: 100%;
  }

  .cont-dest{
    .dest{
      width: 45%;
      .imagen{
        height: 15rem;
      }
      .ficha{
        margin-top: -2rem;
      }
      .textos{
        flex-direction: column;
      }
      .btn-general{
        text-align: center;
      }
    }
  }

  .productos{
    flex-wrap: wrap;
    .filtros{
      width: 100%;
      margin-bottom: 20px;
    }
    .cont-productos{
      width: 100%;
      .buttons{
        display: none;
      }
      .producto.mosaic{
        .mosaic{
          flex-direction: column;
          img{
            width: 100%;
            margin-left: 10px;
            height: auto;
          }
        }
      }
    }
  }
  .cont-pagos{
    flex-wrap: wrap;
    form{
      width: 100%;
    }
  }
  .alternate{
    flex-direction: column-reverse !important;
    .textos{
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
  .cont-contacto{
    flex-wrap: wrap;
    .contacto{
      width: 100%;
    }
    .mapa{
      width: 100%;
    }
  }

}


@media (max-width: 768px) {
  .desk-only{
    display: none !important;
  }
}

/* @include breakpoint(mobileland){

} */
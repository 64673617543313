
@import "mixins.scss";


@media (min-width: 1200px) and (orientation: landscape) and (max-width: 1920px) {

    html, body{
        font-size: 1.3vw;
    }

    h1{
        font-size: 2.3vw;
    }
    h2{
        font-size: 1.9vw;
    }
    h3{
        font-size: 1.7vw;
    }
    h4{
        font-size: 1.4vw;
    }
    p{
        font-size: 1.3vw;
    }
    
}
$black: #231F20;
$brown1: #3B1910;
$brown2: #B96132;
$beige: #F2ECDD;


.color-black{
    color: $black;
}
.color-brown1{
    color: $brown1;
}
.color-brown2{
    color: $brown2;
}
.color-beige{
    color: $beige;
}
.color-white{
    color: white;
}

.bg-color-black{
    background-color: $black;
}
.bg-color-brown1{
    background-color: $brown1;
}
.bg-color-brown2{
    background-color: $brown2;
}
.bg-color-beige{
    background-color: $beige;
}
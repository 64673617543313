.color-black {
  color: #231F20;
}

.color-brown1 {
  color: #3B1910;
}

.color-brown2 {
  color: #B96132;
}

.color-beige {
  color: #F2ECDD;
}

.color-white {
  color: white;
}

.bg-color-black {
  background-color: #231F20;
}

.bg-color-brown1 {
  background-color: #3B1910;
}

.bg-color-brown2 {
  background-color: #B96132;
}

.bg-color-beige {
  background-color: #F2ECDD;
}

@font-face {
  font-family: 'Futura Medium';
  src: url("../fonts/Futura.ttf") format("ttf");
  font-style: normal;
}

@font-face {
  font-family: 'Futura Bold';
  src: url("../fonts/FuturaBold.ttf") format("ttf");
  font-weight: normal;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  font-family: 'Futura Medium', Futura, Arial, sans-serif;
  font-weight: 300;
  margin: 0;
}

html:focus, body:focus {
  outline: none;
}

h1, h2 {
  font-family: 'Futura Bold', Futura, Arial, sans-serif;
  font-weight: bold;
  margin: 0;
  line-height: 1;
  color: #3B1910;
}

h3, h4, h5, h6, p {
  margin: 0;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1.1rem;
}

p {
  font-size: 1rem;
}

:focus {
  outline: none;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

button {
  cursor: pointer;
}

.top {
  background-color: #231F20;
}

.top a {
  color: #B96132;
}

.top .redes a {
  font-size: 2rem;
  margin: 0 10px;
}

.top .side-nav {
  display: flex;
  align-items: center;
}

.top .side-nav a {
  margin: 0 15px;
  color: #F2ECDD;
  font-size: .8rem;
}

.top .side-nav .idiomas {
  margin-left: 30px;
  color: #B96132;
}

.top .side-nav .idiomas a {
  margin: 0;
  color: #B96132;
}

header {
  background-color: #3B1910;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .logo {
  height: 80px;
  padding: 10px 0;
}

header nav {
  height: 100%;
}

header nav a, header nav span {
  margin: 0 10px;
  line-height: 100%;
  font-size: .9rem;
  text-transform: uppercase;
  color: #B96132;
  /* padding: 36px 5px; */
  border-bottom: 3px solid transparent;
  display: flex;
  align-items: center;
}

header nav a:hover, header nav a:active, header nav a.active, header nav span:hover, header nav span:active, header nav span.active {
  color: #F2ECDD;
  border-bottom: 3px solid #F2ECDD;
}

header nav .preg {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 30px 30px;
  margin: 0;
  background-color: #B96132;
  color: #F2ECDD;
}

header nav .preg img {
  margin: 0 10px;
  height: 30px;
}

header nav .preg:hover, header nav .preg:active, header nav .preg.active {
  border-bottom: 3px solid transparent;
}

header nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

header nav ul li {
  position: relative;
}

header nav ul li a, header nav ul li span {
  height: 100%;
  text-decoration: none;
}

header nav ul li a:hover, header nav ul li span:hover {
  text-decoration: none;
}

header nav ul li ul {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: #3B1910;
  z-index: 10;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header nav ul li ul li a {
  white-space: nowrap;
  margin: 0;
  padding: 0.5vw 1vw;
}

header nav ul li ul li a:hover {
  background-color: white;
  color: #3B1910;
}

header nav ul li:hover ul {
  opacity: 1;
  visibility: visible;
}

.main-search {
  height: 500px;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-search form {
  width: 40%;
}

.main-search form input[type='radio'] {
  display: none;
}

.main-search form input[type='radio']:checked + label {
  background-color: #B96132;
  color: #F2ECDD;
}

.main-search label {
  padding: 15px 20px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50px;
  color: #B96132;
  cursor: pointer;
  margin-bottom: 10px;
}

.main-search .searchbox {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.main-search .searchbox input {
  flex-grow: 1;
  border: 0;
  font-size: 1.1rem;
  border-radius: 10px 0 0 10px;
}

.main-search .searchbox button {
  font-size: 1rem;
  border-radius: 0 10px 10px 0;
  padding: 15px;
  border: 0;
  background-color: #fff;
}

.main-search .searchbox button i {
  margin: 0 5px;
}

.main-search .scroll {
  color: #3B1910;
  margin-top: 25px;
  transform: translateY(100%);
  font-size: 1rem;
}

.main-search .scroll:before {
  margin: auto;
  content: '';
  display: block;
  width: 2px;
  height: 60px;
  background-color: #3B1910;
}

footer {
  /* display: flex; */
  background-color: #F2ECDD;
  /* margin: 0;
  padding: 50px 5vw;
  align-items: center;
  justify-content: space-evenly; */
  /* .nav-foot, .logo, .nslt{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  } */
}

footer .nav-foot {
  /* max-height: 300px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer .nav-foot a {
  width: 45%;
  display: block;
  color: black;
  font-size: 1rem;
}

footer .logo {
  /*     align-items: center; */
}

footer .logo img {
  width: 12rem;
}

footer .nslt h4 {
  font-weight: 400;
}

footer .nslt form {
  /* button{
        padding: 10px 20px;
        border-radius: 8px;
        border: 0;
        font-weight: 700;
      } */
}

footer .nslt form input {
  width: 70%;
  border: 0;
  font-size: 1rem;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

footer .nslt form .btn-general {
  -webkit-border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -moz-border-radius-topright: 20px;
  -moz-border-radius-bottomright: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.destacados {
  /* display: flex;
  padding: 50px 10vw; */
  background: linear-gradient(-54deg, #3b1910 0%, #3b1910 34%, #bb6233 34%, #bb6233 54%, white 54%, white 100%);
}

.destacados .swiper-dest {
  width: 100%;
  overflow: hidden;
}

.destacados .swiper-dest .swiper-slide img {
  width: 100%;
}

.destacados .swiper-det {
  width: 100%;
  overflow: hidden;
}

.destacados .swiper-det .swiper-slide {
  /* padding: 15px 10%; */
}

.destacados .swiper-det .swiper-slide h2 {
  color: white;
  margin-bottom: 30px;
}

.destacados .swiper-det .swiper-slide p {
  color: white;
}

.cont-empresa .imagenes img {
  width: 24%;
}

.cont-empresa .texto {
  padding: 30px 0;
}

.cont-empresa .texto h1 {
  margin-bottom: 20px;
}

.cont-empresa .texto p {
  margin-bottom: 10px;
}

.cont-empresa .img-changer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.cont-empresa .img-changer .minis {
  margin-top: -60px;
}

.cont-empresa .img-changer .img {
  width: 80%;
}

.cont-empresa .img-changer .img .main {
  width: 100%;
  position: relative;
}

.cont-empresa .img-changer .img .minis {
  display: flex;
  position: relative;
  z-index: 10;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.cont-empresa .img-changer .img .minis::before {
  height: 58px;
  width: 100%;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(59, 25, 16, 0.8);
}

.cont-empresa .img-changer .img .minis img {
  cursor: pointer;
  width: 25%;
  position: relative;
  z-index: 8;
}

.cont-empresa .img-changer .textos-img {
  width: 100%;
  background-color: #3B1910;
}

.cont-empresa .img-changer .textos-img h3 {
  color: white;
  margin-bottom: 15px;
  /* font-size: 1.4rem; */
}

.cont-empresa .img-changer .textos-img p {
  color: white;
  /* font-size: 1.1rem; */
}

.hidden {
  display: none;
}

.banner {
  width: 100vw;
}

.banner img {
  width: 100%;
}

.alternate {
  display: flex;
  justify-content: space-between;
}

.alternate:nth-of-type(2n) {
  flex-direction: row-reverse;
}

.alternate .textos {
  width: 45%;
}

.alternate .textos h2 {
  text-align: left;
  color: #B96132;
  font-weight: 400;
}

.alternate img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.swiper-causa {
  position: relative;
}

.swiper-causa img {
  width: 100%;
  padding: 0 30px;
}

.swiper-causa .prev, .swiper-causa .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  z-index: 10000;
  font-size: 2rem;
  cursor: pointer;
}

.swiper-causa .prev {
  left: 0;
}

.swiper-causa .next {
  right: 0;
}

.cont-contacto {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-contacto .gracias {
  /* width: 50%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cont-contacto .gracias .thumb {
  font-size: 9rem;
  text-align: center;
  color: #3B1910;
}

.cont-contacto .gracias h3, .cont-contacto .gracias h4 {
  text-align: center;
  color: #3B1910;
}

.cont-contacto .contacto {
  /* width: 50%; */
  /* button{
      background-color: $brown2;
      border-radius: 40px;
      padding: 10px 20px;
      border: 0;
      font-weight: 700;
      color: $beige;
      display: block;
      width: max-content;
      margin: 10px 0 10px auto;
    } */
}

.cont-contacto .contacto label {
  display: block;
  font-size: 1.2rem;
  margin: 20px 0 5px;
}

.cont-contacto .contacto input {
  display: block;
  padding: 10px;
  background-color: rgba(185, 97, 50, 0.1);
  width: 100%;
  border: 0;
  border-radius: 10px;
}

.cont-contacto .contacto textarea {
  background-color: rgba(185, 97, 50, 0.1);
  border-radius: 10px;
  border: 0;
  resize: none;
  padding: 10px;
  display: block;
  width: 100%;
  height: 200px;
}

.cont-contacto .mapa .llamanos {
  background-color: #3B1910;
  border-radius: 40px;
  padding: 10px 20px;
  border: 0;
  font-weight: 700;
  color: #F2ECDD;
  display: block;
  width: max-content;
  margin: 10px 0 10px auto;
}

.cont-contacto .mapa #map {
  width: 100%;
  height: 420px;
}

.cont-pagos {
  display: flex;
}

.cont-pagos form {
  width: 50%;
  padding: 20px;
}

.cont-pagos form h2 {
  text-align: left;
}

.cont-pagos form label {
  display: block;
  font-size: 1.2rem;
  margin: 20px 0 5px;
}

.cont-pagos form input {
  display: block;
  padding: 10px;
  background-color: rgba(185, 97, 50, 0.1);
  width: 100%;
  border: 0;
  border-radius: 10px;
}

.cont-pagos form textarea {
  background-color: rgba(185, 97, 50, 0.1);
  border-radius: 10px;
  border: 0;
  resize: none;
  padding: 10px;
  display: block;
  width: 100%;
  height: 200px;
}

.cont-pagos form button {
  background-color: #B96132;
  border-radius: 40px;
  padding: 10px 20px;
  border: 0;
  font-weight: 700;
  color: #F2ECDD;
  display: block;
  width: max-content;
  margin: 10px 0 10px auto;
}

.cont-servicios {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: flex-start;
}

.cont-servicios .serv {
  width: 26%;
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  color: black;
}

.cont-servicios .serv .imagen {
  position: relative;
}

.cont-servicios .serv .imagen img {
  width: 100%;
}

.cont-servicios .serv .imagen h4 {
  position: absolute;
  bottom: 5px;
  left: 0;
  text-align: center;
  padding: 10px;
  right: 0;
  color: #F2ECDD;
  background-color: rgba(59, 25, 16, 0.6);
}

.cont-servicios .serv p {
  text-align: center;
}

.bar {
  width: 100%;
  height: 2px;
  background-color: #3B1910;
}

.cont-dest {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.cont-dest .dest {
  width: 30%;
  display: block;
  /* color: $brown1; */
}

.cont-dest .dest .imagen {
  position: relative;
  height: 25vw;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-dest .dest .imagen a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.cont-dest .dest .imagen .cant {
  position: absolute;
  top: 0;
  left: 0;
  color: #F2ECDD;
  /* padding: 15px 60px 15px 25px; */
  background-color: #3B1910;
  font-size: 1rem;
  border-radius: 0 0 20px 0;
}

.cont-dest .dest .imagen img {
  /* width: 100%; */
  height: 115%;
}

.cont-dest .dest .ficha {
  width: 100%;
  margin: 0 auto;
  margin-top: -4vw;
  position: relative;
  z-index: 1;
  background-color: #B96132;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.cont-dest .dest h4 {
  width: 100%;
  color: white;
  font-size: 0.9rem;
  text-align: center;
  min-height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-dest .dest .note {
  border-radius: 20px 0 0px 0px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #3B1910;
  color: white;
}

.cont-dest .dest .note div {
  text-align: center;
  /* margin: 0 10px; */
}

.cont-dest .dest .item-op {
  width: 3rem;
  height: auto;
  border: 1px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* &:nth-child(1){
        border: 0;
      } */
}

.cont-dest .dest .item-op i {
  font-size: 1.2rem;
}

.cont-dest .dest .item-op span {
  font-size: 1rem;
}

.cont-dest .btn-general {
  border: 0;
}

.producto {
  display: flex;
  justify-content: space-between;
}

.producto .line {
  height: 2px;
  width: 100%;
  background-color: #B96132;
}

.producto .imagenes {
  /* width: 50%; */
  /* display: flex;
    flex-direction: column; */
}

.producto .imagenes h1 {
  margin: 10px 0;
}

.producto .imagenes h2 {
  text-align: left;
  /* font-size: 1.3rem; */
  margin: 10px 0;
}

.producto .imagenes iframe {
  min-width: 45vw;
}

.producto .imagenes .carousel {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.producto .imagenes .carousel .btn3d {
  width: 18%;
  background-color: #3B1910;
  border: 0;
  margin-right: 20px;
  min-height: 70px;
  margin-bottom: 30px;
}

.producto .imagenes .carousel .btn3d img {
  width: 50%;
}

.producto .imagenes .carousel .btnvideo {
  width: 18%;
  background-color: #3B1910;
  border: 0;
  min-height: 70px;
  color: #FFF;
  font-size: 1.4vw;
  margin-bottom: 30px;
}

.producto .imagenes .carousel .swiper-container {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 8px;
  margin-left: 0;
  position: relative;
}

.producto .imagenes .carousel .swiper-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.producto .imagenes .carousel .swiper-container .next {
  position: absolute;
  z-index: 10000;
  right: 0px;
  top: 0;
  padding: 0 10px;
  bottom: 0;
  border-radius: 0 8px 8px 0;
  display: flex;
  background-color: #B96132;
  color: beige;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.producto .imagenes .carousel .swiper-container .prev {
  position: absolute;
  z-index: 10000;
  left: 0px;
  top: 0;
  cursor: pointer;
  padding: 0 10px;
  bottom: 0;
  border-radius: 8px 0 0 8px;
  display: flex;
  background-color: #B96132;
  color: beige;
  justify-content: center;
  align-items: center;
}

.producto .contact {
  /* width: 40%; */
}

.producto .contact h3 {
  /* background-color: $brown1;
      color: $beige; */
  color: #3B1910;
  text-align: left;
  /* border-radius: 50px 50px 0 50px;
      padding: 15px; */
}

.producto .contact .links .link i {
  font-size: 1rem;
}

.producto .contact .links .link span {
  font-size: 0.8rem;
  line-height: 100%;
}

.producto .contact .links .l1 {
  background-color: #B96132;
  color: #F2ECDD;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 2rem;
  height: 40px;
  border-radius: 50%;
}

.producto .contact .links .l2 {
  background-color: #B96132;
  color: white;
  border-radius: 0 0 0 50px;
  padding: 10px;
  width: 40%;
  text-align: center;
  margin-right: 5px;
  font-size: 1.4rem;
}

.producto .contact .links .l3 {
  background-color: #B96132;
  color: white;
  border-radius: 0 0 50px 0;
  padding: 10px;
  width: 40%;
  text-align: center;
  font-size: 1.4rem;
}

.producto h4 {
  color: #3B1910;
  margin: 15px 0;
}

.producto form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.producto form label {
  width: 20%;
  margin: 10px 0;
}

.producto form input {
  width: 79%;
  margin: 10px 0;
  padding: 10px;
  background-color: rgba(185, 97, 50, 0.1);
  border-radius: 8px;
  border: 0;
}

.producto form textarea {
  border: 0;
  width: 100%;
  resize: none;
  background-color: rgba(185, 97, 50, 0.1);
  border-radius: 8px;
  height: 100px;
  padding: 10px;
  margin: 10px 0;
}

.producto form span {
  font-size: .7rem;
  color: #3B1910;
}

.producto form .terminos p {
  font-size: 0.9rem;
  line-height: 100%;
}

.producto form button {
  line-height: 100%;
}

.producto .note i {
  font-size: 1rem;
}

.producto .note span {
  display: block;
}

.producto .note .item {
  /* width: 30%; */
  text-align: center;
}

.producto .note .item i {
  font-size: 1.3rem;
}

.producto .note .item span {
  font-size: 1rem;
  /* white-space: nowrap; */
}

.producto .imagen {
  position: relative;
}

.producto .imagen .cant {
  position: absolute;
  top: 0;
  left: 0;
  color: #F2ECDD;
  /* padding: 15px 60px 15px 25px; */
  background-color: #3B1910;
  font-size: 1rem;
  border-radius: 0 0 50px 0;
}

.producto .imagen img {
  width: 100%;
}

.line {
  height: 2px;
  width: 100%;
  background-color: #B96132;
}

/* .detalles{
  display: flex;
  justify-content: space-between;
} */
.gastos h2 {
  background-color: #3B1910;
  color: #F2ECDD;
  padding: 15px;
  border-radius: 50px;
}

.gastos img {
  width: 100%;
}

.nice-select .list {
  left: inherit;
  right: 0;
  max-height: 30vh;
  overflow-y: scroll;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.nice-select .list::-webkit-scrollbar {
  width: 10px;
}

.nice-select .list::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
  border: 1px solid #eeeeee;
}

.nice-select .list::-webkit-scrollbar-thumb {
  background: #B96132;
  border-radius: 10px;
}

.nice-select .list::-webkit-scrollbar-thumb:hover {
  background: #B96132;
}

.productos {
  display: flex;
  justify-content: space-between;
}

.productos .filtros {
  /* width: 20%; */
}

.productos .filtros .tipo {
  cursor: pointer;
}

.productos .filtros .tipo.abierto h3 i {
  transform: rotateZ(180deg);
}

.productos .filtros .tipo.abierto .opciones {
  display: block;
}

.productos .filtros .tipo h4 {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #B96132;
  font-weight: 400;
}

.productos .filtros .tipo .opciones {
  border-bottom: 2px solid #B96132;
  background-color: rgba(185, 97, 50, 0.1);
  padding: 15px 20px;
  display: none;
}

.productos .filtros .tipo .opciones a {
  display: block;
  /* margin: 10px 0; */
  color: black;
  font-size: 1rem;
}

.productos .filtros .tipo .opciones a.active {
  background: url("/images/check.png") right center no-repeat;
}

.productos .filtros .tipo .formfiltros {
  /* display: flex;
        justify-content: space-between;
        align-items: center; */
  width: 100%;
  display: inline-block;
  margin: 5px 0;
}

.productos .filtros .tipo .formfiltros .nice-select {
  width: 100%;
}

.productos .filtros .tipo .formfiltros .nice-select .current {
  width: 94%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.productos .filtros .tipo .formfiltros .nice-select::after {
  top: 25%;
}

.productos .filtros .tipo .formfiltros label {
  width: 100%;
  font-size: 1rem;
}

.productos .cont-productos {
  /* width: 75%; */
}

.productos .cont-productos .buttons button {
  border: 0;
  opacity: .6;
  padding: 0;
  margin-right: 10px;
}

.productos .cont-productos .buttons button.active {
  opacity: 1;
}

.productos .cont-productos .buttons button img {
  height: 15px;
}

.productos .cont-productos .producto-min {
  /* &.mosaic{
        .mosaic{
          display: flex;
          width: 100%;
        }
      }
      &.list{
        .list{
          display: flex;
        }
      }
      .left{
        display: flex;

        img{
          float: left;
          width: 20%;
          height: 180px;
          object-fit: cover;
        }
        .cant{
          margin-left: 10px;

          background-color: $brown2;
          color: white;
          padding: 10px 30px;
          width: max-content;
          border-radius: 0 50px 50px 0;

        }
        h3{
          margin: 15px 10px;
        }
        p{
          margin: 10px;
          width: 40%;
        }
        .note{
          background-color: $brown1;
          color: white;
          margin-left: 10px;
          border-radius: 0 50px 50px 0;
          i{
            font-size:  1.7rem;
          }
        }
      }
      .right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a{
          background-color: $brown2;
          color: white;
          padding: 10px 30px;
          width: max-content;
          border-radius: 50px;
        }
      }
      .mosaic, .list{
        display: none;
      } */
}

.productos .cont-productos .producto-min .texts h3 {
  line-height: 120%;
  font-size: 1rem;
  min-height: 3rem;
}

.productos .cont-productos .producto-min .texts h3 a {
  color: black;
  text-decoration: none;
}

.productos .cont-productos .producto-min .texts .especificaciones {
  width: 100%;
}

.productos .cont-productos .producto-min .items {
  /* width: 100%; */
}

.productos .cont-productos .producto-min .items .item {
  text-align: center;
}

.productos .cont-productos .producto-min .items .item i, .productos .cont-productos .producto-min .items .item span {
  color: #3B1910;
}

.productos .cont-productos .producto-min .items .item i {
  font-size: 1rem;
}

.productos .cont-productos .producto-min .items .item span {
  font-size: 1rem;
}

.empleados {
  border-top: 2px solid #3B1910;
  /* margin: 20px 0; */
}

.empleados .tabs {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.empleados .tabs button {
  width: 22%;
  border: 0;
  background-color: transparent;
  color: #3B1910;
  border: 1px solid #3b1910;
  cursor: pointer;
  border-radius: 50px;
  font-size: 1rem;
  /* font-family: "Futura"; */
}

.empleados .tabs button.active {
  color: #F2ECDD;
  background-color: #3B1910;
}

.empleados .next, .empleados .prev {
  position: relative;
  right: inherit;
  left: inherit;
  top: inherit;
  bottom: inherit;
  cursor: pointer;
  font-size: 1rem;
}

.empleados .swiper-container {
  width: 90%;
}

.empleados .swiper-sec1, .empleados .swiper-sec2, .empleados .swiper-sec3, .empleados .swiper-sec4 {
  /* padding-left: 20px;
    padding-right: 20px; */
  /*  .next{
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 9999;
      font-size:2rem;
      height: 50px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .prev{
      position: absolute;
      left: 0;
      font-size:2rem;
      top: 50%;
      z-index: 9999;
      height: 50px;
      transform: translateY(-50%);
      cursor: pointer;
    } */
}

.empleados .swiper-sec1 .imagen, .empleados .swiper-sec2 .imagen, .empleados .swiper-sec3 .imagen, .empleados .swiper-sec4 .imagen {
  position: relative;
}

.empleados .swiper-sec1 .imagen img, .empleados .swiper-sec2 .imagen img, .empleados .swiper-sec3 .imagen img, .empleados .swiper-sec4 .imagen img {
  width: 100%;
  margin-bottom: 0;
}

.empleados .swiper-sec1 .imagen h4, .empleados .swiper-sec2 .imagen h4, .empleados .swiper-sec3 .imagen h4, .empleados .swiper-sec4 .imagen h4 {
  margin: 0;
  background-color: #B96132;
  color: white;
  text-align: center;
  /* position: absolute;
        left: 0;
        right: 0;
        bottom: 0; */
}

.empleados .swiper-sec1 .txt, .empleados .swiper-sec2 .txt, .empleados .swiper-sec3 .txt, .empleados .swiper-sec4 .txt {
  font-size: 1rem;
}

.empleados .swiper-sec1 .buttons, .empleados .swiper-sec2 .buttons, .empleados .swiper-sec3 .buttons, .empleados .swiper-sec4 .buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5px;
}

.empleados .swiper-sec1 .buttons a, .empleados .swiper-sec2 .buttons a, .empleados .swiper-sec3 .buttons a, .empleados .swiper-sec4 .buttons a {
  width: 49.5%;
  background-color: #3B1910;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empleados .swiper-sec1 .buttons a.tel, .empleados .swiper-sec2 .buttons a.tel, .empleados .swiper-sec3 .buttons a.tel, .empleados .swiper-sec4 .buttons a.tel {
  border-radius: 0 0 0 70px;
}

.empleados .swiper-sec1 .buttons a.mail, .empleados .swiper-sec2 .buttons a.mail, .empleados .swiper-sec3 .buttons a.mail, .empleados .swiper-sec4 .buttons a.mail {
  border-radius: 0 0 70px 0;
}

.empleados .swiper-sec1 p, .empleados .swiper-sec2 p, .empleados .swiper-sec3 p, .empleados .swiper-sec4 p {
  color: #939598;
  margin-top: 5%;
  text-align: justify;
}

.swal2-header {
  background-color: #3B1910;
}

.swal2-header .swal2-title {
  margin: 10px 0;
  width: 100%;
  justify-content: flex-end;
}

.swal2-header .headMod img {
  width: 100px;
}

.modalHistoria {
  padding: 20px 0;
}

.modalHistoria img {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.modalHistoria h2 {
  text-align: left;
}

.modalHistoria p {
  text-align: justify;
}

.btn-general {
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-general:hover {
  background-color: white;
  color: #3B1910;
  text-decoration: none;
  border-color: #3B1910;
}

.rounded-20 {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.rounded-30 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* #verHistoria{
  width: 15%;
  padding: 10px 20px;
  margin: 10px 0 10px 85%;
  border: 0;
  background-color: transparent;
  color: white;
  background-color: $brown2;
  cursor: pointer;
  border-radius: 50px;
  font-size:1.1rem;
  font-family: "Futura";
} */
.agregar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3B1910;
  border-radius: 0 0 30px 30px;
  padding: 40px 0;
}

.agregar h3 {
  color: white;
}

.agregar a {
  padding: 10px 20px;
  border: 0;
  margin: 10px 0;
  background-color: transparent;
  color: white;
  background-color: #B96132;
  cursor: pointer;
  border-radius: 50px;
  font-family: "Futura";
}

.selectorTipo {
  position: relative;
  padding: 30px 10vw;
}

.selectorTipo .fondo {
  position: absolute;
  top: 0;
  height: 50%;
  width: 100%;
  left: 0;
  right: 0;
  object-fit: cover;
  z-index: -1;
  overflow: hidden;
}

.selectorTipo .swiper-consigna {
  margin-top: 30px;
  padding: 0 20px;
}

.selectorTipo .swiper-consigna .next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9999;
}

.selectorTipo .swiper-consigna .prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9999;
}

.selectorTipo .swiper-consigna .swiper-slide input {
  display: none;
}

.selectorTipo .swiper-consigna .swiper-slide label {
  cursor: pointer;
  position: relative;
  border: 5px solid #3B1910;
  display: block;
}

.selectorTipo .swiper-consigna .swiper-slide label h3 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #FFFFFF;
  background-color: #3B1910;
  padding: 10px 0;
}

.selectorTipo .swiper-consigna .swiper-slide input:checked + label {
  border-color: #B96132;
}

.selectorTipo .swiper-consigna .swiper-slide img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.selectorTipo .selectDuracion {
  display: flex;
  justify-content: space-evenly;
  padding-top: 40px;
}

.selectorTipo .selectDuracion input {
  display: none;
}

.selectorTipo .selectDuracion input:checked + label::after {
  width: 20px;
  height: 20px;
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 5px;
  border-radius: 50%;
  transform: translateX(-50%);
  background-color: #3B1910;
}

.selectorTipo .selectDuracion label {
  position: relative;
  cursor: pointer;
  text-align: center;
  display: block;
}

.selectorTipo .selectDuracion label::before {
  margin: auto;
  width: 30px;
  height: 30px;
  content: "";
  display: block;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 2px solid #3B1910;
}

.cont-empresa.consigna::before {
  display: block;
  content: "";
  height: 2px;
  width: 100%;
  margin: 20px auto;
  background-color: #3B1910;
}

.cont-empresa.consigna h2 {
  text-align: left;
  /* font-size: 1.1rem; */
}

.cont-empresa.consigna input[type="file"] {
  display: none;
}

.cont-empresa.consigna #fotosLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: block;
  padding: 40px 0;
  width: 100%;
  border: 2px dashed #3B1910;
}

.cont-empresa.consigna .inputConsigna {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.cont-empresa.consigna .inputConsigna label {
  margin-right: 20px;
  width: 20%;
  text-align: right;
}

.cont-empresa.consigna .inputConsigna input {
  width: 40%;
  padding: 5px;
}

.cont-empresa.consigna.doble {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cont-empresa.consigna.doble .izq {
  width: 40%;
}

.cont-empresa.consigna.doble .izq .inputConsigna input {
  width: 25%;
}

.cont-empresa.consigna.doble .der {
  width: 40%;
}

.cont-empresa.consigna.doble .der textarea {
  width: 70%;
  height: 140px;
  background-color: rgba(185, 97, 50, 0.2);
  color: white;
  border-radius: 20px;
  padding: 10px;
  resize: none;
  border: 0;
}

.enviar {
  width: max-content;
  padding: 10px 20px;
  display: block;
  margin: 20px auto;
  font-size: 1.1rem;
  border: 0;
  background-color: transparent;
  color: white;
  background-color: #B96132;
  cursor: pointer;
  border-radius: 50px;
  font-family: "Futura";
}

.faqs .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faqs .tabs button {
  line-height: 100%;
}

.faqs .tabs button.active {
  color: #F2ECDD;
  background-color: #3B1910;
}

.faqs .pregunta {
  cursor: pointer;
  /*  h2{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
      span{
        width: max-content;
        padding: 10px 20px;
        margin-left: 15px;
        font-size: .9rem;
        background-color: $brown2;
        color: white;
        border-radius: 40px;
      }
    } */
}

.faqs .pregunta .respuesta {
  display: none;
}

.mob-only {
  display: none;
}

input, textarea {
  font-size: 3.5vmin;
}

b {
  font-weight: bold;
}

.img-whatsapp {
  height: 70px;
  right: 10%;
  position: absolute;
  margin: 10px 20px;
}

.historia h2 {
  color: white;
  font-size: 1.5rem;
}

.historia p {
  color: white;
  font-size: 1rem;
}

/* @include breakpoint(desktop){

}
@include breakpoint(biglaptop) {

}
@include breakpoint(laptop){

}
@include breakpoint(tablet) {

}
@include breakpoint(phablet) {

} */
@media (max-width: 45em) {
  body {
    width: 100%;
    height: 100%;
  }
  html, body {
    font-size: 1rem;
  }
  .slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 256px;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
  }
  .slideout-menu-left {
    left: 0;
  }
  .slideout-menu-right {
    right: 0;
  }
  .slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    background-color: #FFF;
    /* A background-color is required */
    min-height: 100vh;
  }
  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }
  .slideout-open .slideout-menu {
    display: block;
  }
  .mob-only {
    display: block;
  }
  .top {
    flex-wrap: wrap;
  }
  .top .redes {
    width: 100%;
  }
  .top .side-nav {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .top .side-nav a {
    width: 40%;
  }
  .top .side-nav a:first-of-type {
    width: 100%;
  }
  .top .side-nav .idiomas {
    margin-left: 15px;
  }
  .producto {
    flex-wrap: wrap;
  }
  .producto .note {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .producto .note div {
    width: 40%;
    margin: 20px 10px;
  }
  .producto .imagenes {
    width: 100%;
  }
  .producto .imagenes .carousel .btn3d, .producto .imagenes .carousel .btnvideo {
    font-size: 1rem;
  }
  .producto .contact {
    width: 100%;
  }
  header {
    padding: 0 5vw;
    justify-content: flex-start;
  }
  header .toggle-button {
    background-color: transparent;
    color: white;
    font-size: 1.2rem;
    border: 0;
    margin-right: 20px;
  }
  /* html, body{
    font-size: 4vw;
  } */
  .detalles {
    flex-wrap: wrap;
  }
  .detalles .tengo, .detalles .cerca {
    width: 100%;
  }
  #menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #F2ECDD;
  }
  #menu .logo {
    height: 3rem;
  }
  #menu ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #menu ul li ul {
    padding-left: 1.5rem;
  }
  #menu a {
    color: #3B1910;
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #menu a.preg {
    background-color: #B96132;
    color: white;
    justify-content: center;
  }
  #menu a.preg img {
    margin-right: 1rem;
  }
  #menu a:hover {
    color: white;
    background-color: #3B1910;
  }
  .faqs .tabs {
    flex-wrap: wrap;
  }
  .faqs .tabs button {
    width: 100%;
    font-size: 1rem;
    margin: 10px 0;
  }
  .faqs .pregunta h2 {
    flex-wrap: wrap;
  }
  .faqs .pregunta h2 strong {
    width: 100%;
    text-align: left;
  }
  .faqs .pregunta h2 span {
    margin: 10px 0 15px;
  }
  .img-changer {
    flex-direction: column;
    margin: 55px auto 0;
  }
  .img-changer .img {
    width: 100%;
  }
  .img-changer .textos-img {
    width: 100%;
  }
  .empleados .tabs {
    flex-wrap: wrap;
  }
  .empleados .tabs button {
    width: 100%;
    font-size: 1rem;
    margin: 10px 0;
  }
  .secciontab .swiper-slide {
    padding: 20px;
  }
  .cont-servicios .serv {
    width: 100%;
  }
  .cont-dest {
    width: 100%;
  }
  .cont-dest .dest {
    width: 45%;
  }
  .cont-dest .dest .imagen {
    height: 15rem;
  }
  .cont-dest .dest .ficha {
    margin-top: -2rem;
  }
  .cont-dest .dest .textos {
    flex-direction: column;
  }
  .cont-dest .dest .btn-general {
    text-align: center;
  }
  .productos {
    flex-wrap: wrap;
  }
  .productos .filtros {
    width: 100%;
    margin-bottom: 20px;
  }
  .productos .cont-productos {
    width: 100%;
  }
  .productos .cont-productos .buttons {
    display: none;
  }
  .productos .cont-productos .producto.mosaic .mosaic {
    flex-direction: column;
  }
  .productos .cont-productos .producto.mosaic .mosaic img {
    width: 100%;
    margin-left: 10px;
    height: auto;
  }
  .cont-pagos {
    flex-wrap: wrap;
  }
  .cont-pagos form {
    width: 100%;
  }
  .alternate {
    flex-direction: column-reverse !important;
  }
  .alternate .textos {
    width: 100%;
  }
  .alternate img {
    width: 100%;
  }
  .cont-contacto {
    flex-wrap: wrap;
  }
  .cont-contacto .contacto {
    width: 100%;
  }
  .cont-contacto .mapa {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .desk-only {
    display: none !important;
  }
}

/* @include breakpoint(mobileland){

} */
@media (min-width: 768px) {
  .faqs .pregunta h3 strong {
    width: 75%;
  }
  .faqs .pregunta h3 .btn-general {
    width: 25%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .productos .cont-productos .producto-min {
    width: 45%;
  }
  .productos .cont-productos .producto-min .texts h3 {
    min-height: 4.5rem;
  }
}

@media (min-width: 1200px) {
  .productos .cont-productos .producto-min {
    width: 33%;
  }
}

@media (min-width: 1200px) and (orientation: landscape) and (max-width: 1920px) {
  header .logo {
    height: 6.2vw;
  }
  header nav a, header nav span {
    font-size: 1vw;
  }
  .btn-general {
    font-size: 1.3vw;
  }
  .empleados .tabs button {
    font-size: 1.2vw;
  }
  .empleados .swiper-sec1 .txt, .empleados .swiper-sec2 .txt, .empleados .swiper-sec3 .txt, .empleados .swiper-sec4 .txt {
    font-size: 1.2vw;
  }
  .empleados .swiper-sec1 .txt b, .empleados .swiper-sec2 .txt b, .empleados .swiper-sec3 .txt b, .empleados .swiper-sec4 .txt b {
    font-weight: bold;
  }
  .empleados .next, .empleados .prev {
    font-size: 2vw;
  }
  .cont-empresa .img-changer .img {
    width: 40%;
  }
  .cont-empresa .img-changer .textos-img {
    width: 60%;
    transform: translate(0, -30px);
  }
  .cont-dest .dest .imagen .cant {
    font-size: 1vw;
  }
  .cont-dest .dest h4 {
    font-size: 1.3vw;
    font-weight: bold;
  }
  .cont-dest .dest .item-op {
    width: 3vw;
  }
  .cont-dest .dest .item-op i {
    font-size: 1.1vw;
  }
  .cont-dest .dest .item-op span {
    font-size: 0.9vw;
  }
  .producto .imagen .cant {
    font-size: 1vw;
  }
  .producto .contact .links .link i {
    font-size: 1.2vw;
  }
  .producto .contact .links .link span {
    font-size: 0.9vw;
  }
  .producto .note .item i {
    font-size: 1.5vw;
  }
  .producto .note .item span {
    font-size: 1.1vw;
  }
  .productos .filtros .tipo .opciones a {
    font-size: 1.2vw;
    line-height: 100%;
  }
  .productos .filtros .tipo .formfiltros label {
    font-size: 1.2vw;
  }
  .productos .filtros .tipo .formfiltros .nice-select {
    /* width: 30%; */
    /* font-size: 1.2vw; */
  }
  .productos .filtros .tipo .formfiltros .nice-select::after {
    width: 1vw;
    height: 1vw;
  }
  .productos .filtros .tipo .formfiltros .nice-select .option {
    font-size: 1vw;
  }
  .productos .cont-productos .producto-min .texts h3 {
    font-size: 1.2vw;
    min-height: 6vw;
  }
  .productos .cont-productos .producto-min .items .item i {
    font-size: 1.2vw;
  }
  .productos .cont-productos .producto-min .items .item span {
    font-size: 1vw;
  }
  .cont-servicios .serv .imagen h4 {
    font-size: 1.7vw;
  }
  footer .nav-foot a {
    font-size: 1vw;
  }
  footer .nav-foot a:hover {
    color: #B96132;
    text-decoration: none;
  }
  footer .logo img {
    height: 10vw;
  }
  footer .nslt form input {
    font-size: 1vw;
  }
  .historia h2 {
    color: white;
    font-size: 1.8vw;
  }
  .historia p {
    color: white;
    font-size: 1.3vw;
  }
}

@media (min-width: 1600px) and (orientation: landscape) and (max-width: 1920px) {
  .container {
    max-width: 1366px;
  }
  .producto .contact .links .link {
    width: 3vw;
    height: 3vw;
  }
  .producto .contact .links .link i {
    font-size: 1.4vw;
  }
  .producto .contact .links .link span {
    font-size: 0.7vw;
  }
  .producto .contact form .terminos p {
    font-size: 0.8vw;
  }
  .producto form label {
    font-size: 1vw;
  }
}

@media (min-width: 1200px) and (orientation: landscape) and (max-width: 1920px) {
  html, body {
    font-size: 1.3vw;
  }
  h1 {
    font-size: 2.3vw;
  }
  h2 {
    font-size: 1.9vw;
  }
  h3 {
    font-size: 1.7vw;
  }
  h4 {
    font-size: 1.4vw;
  }
  p {
    font-size: 1.3vw;
  }
}

@media (min-width: 1921px) {
  .productos .cont-productos .producto-min .texts h3 {
    min-height: 3.5rem;
  }
  .cont-dest .dest .imagen {
    height: 27rem;
  }
}


@import "mixins.scss";


@media (min-width: 768px) {
  
    .faqs{
        .pregunta{
            h3{
                strong{
                    width: 75%;
                }
                .btn-general{
                    width: 25%;
                }
            }
        }
    }
    

}

@media (min-width: 768px) and (max-width: 1200px) {

    .productos {
        .cont-productos {
            .producto-min{
                width: 45%;
                .texts{
                    h3{
                        min-height: 4.5rem;
                    }
                }
            }
        }
    }

}
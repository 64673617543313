@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 45em ) { @content ; }
  }
  @else if $point == biglaptop {
    @media (max-width: 91em)  { @content ; }
  }
  @else if $point == laptop {
    @media (max-width: 70em)  { @content ; }
  }
  @else if $point == tablet {
    @media (max-width: 64em) { @content ; }
  }
  @else if $point == phablet {
    @media (max-width: 50em)   { @content ; }
  }
  @else if $point == mobileonly {
    @media (max-width: 45em)  { @content ; }
  }
  @else if $point == mobileland {
    @media (max-width: 43em) and  (orientation: landscape) { @content ; }
  }
}

@mixin absoluteCenter(){
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin toAnim ($tempo){
  -webkit-transition: all $tempo ease-in-out;
  -moz-transition: all $tempo ease-in-out;
  -o-transition: all $tempo ease-in-out;
  transition: all $tempo ease-in-out;
}
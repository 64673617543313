
@import "mixins.scss";


@media (min-width: 1200px) { 
      
    .productos {
        .cont-productos {
            .producto-min{
                width: 33%;
            }
        }
    }
    
}

@media (min-width: 1200px) and (orientation: landscape) and (max-width: 1920px) {

    header{
        .logo{
            height: 6.2vw;
        }
        nav{
            a, span {
                font-size: 1vw;
            }
        }
    }

    .btn-general{
        font-size: 1.3vw;
    }
    
    .empleados{
        .tabs{
            button{
                font-size: 1.2vw;
            }
        }
        .swiper-sec1, .swiper-sec2, .swiper-sec3, .swiper-sec4{
            .txt{
                font-size: 1.2vw;
                b{
                    font-weight: bold;
                }
            }
        }
        .next, .prev{
            font-size: 2vw;
        }
    }

    .cont-empresa {
        .img-changer {  
            .img{
                width: 40%;
            }
            .textos-img{
                width: 60%;
                transform: translate(0, -30px);
            }
        }
    }

    .cont-dest{
        .dest{
            .imagen{
                .cant{
                    font-size: 1vw;
                }
            }
            h4{
                font-size: 1.3vw;
                font-weight: bold;
            }
            .item-op{
                width: 3vw;
                i{
                    font-size: 1.1vw;
                }
                span{
                    font-size: 0.9vw;
                }
            }
        }
    }

    .producto {
        .imagen {
            .cant{
                font-size: 1vw;
            }
        }
        .contact {
            .links {
                .link{
                    i{
                        font-size: 1.2vw;
                    }
                    span{
                        font-size: 0.9vw;
                    }
                }
            }
        }
        .note{
            .item{
                i{
                    font-size: 1.5vw;
                }
                span{
                    font-size: 1.1vw;
                }
            }
        }
    }


    .productos {
        .filtros{
            .tipo{
                .opciones{
                    a{
                        font-size: 1.2vw;
                        line-height: 100%;
                    }
                }
                .formfiltros{
                    label{
                      font-size: 1.2vw;
                    }
                    .nice-select{
                        /* width: 30%; */
                        /* font-size: 1.2vw; */
                        &::after{
                            width: 1vw;
                            height: 1vw;
                        }
                        .option{
                            font-size: 1vw;
                        }
                    }
                  }
            }
        }

        .cont-productos {
            .producto-min {
                .texts{
                    h3{
                        font-size: 1.2vw;
                        min-height: 6vw;
                    }
                }
                .items{
                    .item{
                        i{
                            font-size: 1.2vw;
                        }
                        span{
                            font-size: 1vw;
                        }
                    }
                }
            }
        }
    }

    .cont-servicios{
        .serv {
            .imagen {
                h4{
                    font-size: 1.7vw;
                }
            }
        }
    }

    footer{
        .nav-foot{
            a{
                font-size: 1vw;
                &:hover{
                    color: $brown2;
                    text-decoration: none;
                }
            }
        }
        .logo{
            img{
                height: 10vw;
            }
        }
        .nslt{
            form{
                input{
                    font-size: 1vw;
                }
            }
        }
    }

    .historia{
        h2{
          color: white;
          font-size: 1.8vw;
        }
        p{
          color: white;
          font-size: 1.3vw;
        }
    }
      

}
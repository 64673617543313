
@import "mixins.scss";


@media (min-width: 1600px) {

    
    
}

@media (min-width: 1600px) and (orientation: landscape) and (max-width: 1920px) {

    .container{
        max-width: 1366px;
    }

    .producto {  
        .contact {
            .links {
                .link{
                    width: 3vw;
                    height: 3vw;
                    i{
                        font-size: 1.4vw;
                    }
                    span{
                        font-size: 0.7vw;
                    }
                }
            }
            form {
                .terminos {
                    p{
                        font-size: 0.8vw;
                    } 
                }
            }
        }  
        form {
            label{
                font-size: 1vw;
            }
        }
    }

}